import './ModalForm.css';
import IconClose from '../../../images/icon-close.svg';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function ModalForm({active, setActive}) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27 && active){
            setActive(false);
          }
        }

        window.addEventListener('keydown', close);

        return () => window.removeEventListener('keydown', close);
    },[active]);

    const handleModalFormActiveClick = () => {
        setActive(false);
    };

    return (
        <div className={active ? 'modal-form active' : 'modal-form'} onClick={handleModalFormActiveClick}>
            <div className="modal-form-content" onClick={e => e.stopPropagation()}>
                <div className="modal-form-content-close-button" onClick={handleModalFormActiveClick}>
                    <span>{t('modal-form-close')}</span>
                    <img src={IconClose} alt={'Icon Close'}/>
                </div>

                <span className="modal-form-content-message">{t('modal-form-message')}</span>

                <button className="modal-form-content-button" onClick={handleModalFormActiveClick}>
                    <span>{t('modal-form-button')}</span>
                </button>
            </div>
        </div>
    )
};

export default ModalForm;