export const languages =[
    {
      code: 'ua',
      name: 'Українська',
      country_code: 'ua'
    },
    {
      code: 'en',
      name: 'English',
      country_code: 'uk'
    },
    {
      code: 'ru',
      name: 'Русский',
      country_code: 'ru'
    }
  ]