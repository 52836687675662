export const dataNav = [
    { to: '/', label: 'main_page' },
    { to: '/services', label: 'services_page' },
    { to: '/about', label: 'about_page' },
    { to: '/contacts', label: 'contacts_page' },
    { to: 'https://archimed.in.ua/uk/vakansii/', label: 'vacancies', target: '_blank' },
    { to: 'https://hartmann24.com.ua/', label: 'shop', target: '_blank' }
];

export const dataSocialIcon = [
    {href: 'https://www.facebook.com/archimed.medical', label: 'facebook'},
    {href: 'https://www.linkedin.com/company/archimed-medical', label: 'linkedin'},
    {href: 'https://www.youtube.com/@Archimed-Regulatory/videos', label: 'youtube'}

]